'use client';
import { useAddStockAlertMutation } from '@akinon/next/data/client/wishlist';
import { useLocalization, useRouter } from '@akinon/next/hooks';
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Link,
  LoaderSpinner,
  Modal
} from '@theme/components';
import { ROUTES } from '@theme/routes';
import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

type Props = {
  productPk: number;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function ProductStockAlertModal({ productPk, open, setOpen }: Props) {
  const [stockAlertStatus, setStockAlertStatus] = useState<'success' | 'error'>(
    null
  );
  const { data: session, status } = useSession();
  const [addStockAlert, { isLoading: isAddToStockAlertLoading }] =
    useAddStockAlertMutation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState<string>(session?.user?.email);
  const router = useRouter();
  const pathName = usePathname();

  useEffect(() => {
    setEmail(session?.user?.email);
  }, [session?.user?.email]);

  if (status == 'unauthenticated' && open === true) {
    router.push(`${ROUTES.AUTH}?callbackUrl=${pathName}`);
  }

  const addProductToStockAlertList = async () => {
    try {
      await addStockAlert({
        productPk,
        email
      })
        .unwrap()
        .then(() => {
          setStockAlertStatus('success');
        })
        .catch((error) => {
          setErrorMessage(error);
          setStockAlertStatus('error');
        });
    } catch (error) {
      setStockAlertStatus('error');
    }
  };

  const { t } = useLocalization();

  const stockAlertTemplates = {
    default: (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addProductToStockAlertList();
        }}
      >
        <p className={clsx('mb-6 text-sm', 'lg:mb-5 lg:text-base')}>
          {t('product.stock_alert.default_description')}
        </p>
        <div>
          <label
            htmlFor="stock-alert-email"
            className="mb-1.5 block text-sm font-medium text-gray-800"
          >
            {t('product.stock_alert.email_label')}
          </label>
          <div
            className={clsx(
              'mb-6 flex  h-10 items-center gap-2 border border-lightBlue-600 px-4 py-2',
              'lg:mb-8'
            )}
          >
            <Icon
              name="mail"
              size={14}
              className="cursor-pointer text-gray-850"
            />

            <Input
              id="stock-alert-email"
              wrapperClassName="w-full"
              onChange={(e) => setEmail(e.currentTarget.value)}
              defaultValue={email}
              required
              type="email"
              placeholder={t('auth.login.form.email.title')}
              className="h-full w-full border-0 px-0 text-base lg:text-base"
            />
            <Icon name="info-circle" size={22} className="text-gray-850" />
          </div>
          <div className="mb-6 flex items-center gap-2 lg:mb-8 lg:gap-3">
            <Checkbox
              className="lg:[&_input]:!h-5 lg:[&_input]:!w-5"
              required
              id="stock-alert-agreement"
            />
            <label
              htmlFor="stock-alert-agreement"
              className={clsx(
                'user-select-none text-sm font-medium',
                'lg:text-base'
              )}
            >
              {t('product.stock_alert.aggreement')}
            </label>
          </div>
          <Button
            disabled={isAddToStockAlertLoading}
            type="submit"
            className="h-12 w-full"
          >
            {isAddToStockAlertLoading ? (
              <LoaderSpinner className="h-6 w-6 border-white border-t-transparent" />
            ) : (
              t('product.add_stock_alert')
            )}
          </Button>
        </div>
      </form>
    ),
    success: (
      <>
        <p className="mb-1 text-sm font-semibold text-success lg:mb-4 lg:text-base">
          {t('product.stock_alert.success.title')}
        </p>
        <p className="mb-2 text-sm lg:mb-2 lg:text-base">
          {t('product.stock_alert.success.description')}
        </p>
        <p className="mb-6 text-sm font-semibold lg:mb-8">{email}</p>
        <div className={clsx('flex flex-col gap-3', 'lg:flex-row lg:gap-4')}>
          <Link
            href={ROUTES.ACCOUNT_WISHLIST}
            className="flex h-12  items-center justify-center border border-primary bg-transparent px-5 text-primary transition-all lg:h-12"
          >
            {t('product.stock_alert.waiting_stock_button')}
          </Link>
          <Button
            onClick={() => {
              setOpen(false);
              setStockAlertStatus(null);
            }}
            className="h-12 flex-grow"
          >
            {t('product.stock_alert.close_button')}
          </Button>
        </div>
      </>
    ),
    error: (
      <>
        <p className="mb-1 text-sm font-semibold text-error lg:mb-2 lg:text-base">
          {t('product.stock_alert.error.title')}
        </p>
        <p className="mb-6 text-sm  lg:mb-8 lg:text-base">
          {Object.keys(errorMessage?.data || {}) &&
            errorMessage?.data?.non_field_errors}{' '}
          {t('product.stock_alert.error.description')}
        </p>

        <Button
          onClick={() => {
            setOpen(false);
            setStockAlertStatus(null);
          }}
          className="h-12 flex-grow"
        >
          {t('product.stock_alert.close_button')}
        </Button>
      </>
    )
  };

  return (
    <Modal
      portalId="stock-alert-modal"
      open={open}
      setOpen={setOpen}
      showCloseButton={false}
      className={clsx(
        'bottom-0 top-auto w-full translate-y-0',
        'lg:bottom-auto lg:top-1/2 lg:max-w-[500px] lg:-translate-y-1/2'
      )}
    >
      <div
        className={clsx(
          'flex flex-col justify-center  p-4 pb-6 ',
          'lg:px-6 lg:pb-8 lg:pt-4'
        )}
      >
        <div className="mb-4 flex items-center justify-between">
          <h4 className={clsx('text-lg font-medium', 'lg:text-2xl')}>
            {t('product.stock_alert.title')}
          </h4>
          <Icon
            onClick={() => setOpen(false)}
            name="close"
            className="cursor-pointer lg:!text-[32px]"
            size={24}
          />
        </div>
        {stockAlertTemplates[stockAlertStatus || 'default']}
      </div>
    </Modal>
  );
}
