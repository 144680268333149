'use client';

import { useAppDispatch } from '@akinon/next/redux/hooks';
import {
  useAddProductMutation,
  AddProductRequest
} from '@akinon/next/data/client/product';
import { basketApi, useGetBasketQuery } from '@akinon/next/data/client/basket';
import { setHighlightedItem } from '@akinon/next/redux/reducers/root';
import { useState } from 'react';
import { useMediaQuery } from '@akinon/next/hooks';
import { useToast } from '@theme/hooks/use-toast';
import { useLocalization } from '@akinon/next/hooks';
import { ROUTES } from '@theme/routes';
import { GIFT_CARD_SKU_LIST } from '@theme/utils/gift-card-sku-list';

export const useAddProductToBasket = () => {
  const dispatch = useAppDispatch();
  const [addProduct, options] = useAddProductMutation();
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isMobile = useMediaQuery('(max-width:768px)');
  const { t } = useLocalization();
  const toast = useToast();
  const { data: basketData } = useGetBasketQuery();

  const containsGiftCard = (basketItems) => {
    if (!basketItems || basketItems.length === 0) {
      return false;
    }

    if (
      basketItems?.find((item) =>
        GIFT_CARD_SKU_LIST.some((sku) => sku == item.product.sku)
      )
    ) {
      return true;
    }
  };

  const addProductToBasket = async ({
    product,
    quantity,
    attributes,
    sku,
    shouldOpenMiniBasket = true
  }: AddProductRequest & { shouldOpenMiniBasket?: boolean; sku: string }) => {
    const hasGiftCard = containsGiftCard(basketData?.basketitem_set);

    if (hasGiftCard && !GIFT_CARD_SKU_LIST.some((item) => item === sku)) {
      setErrorMessage(t('product.add_basket.gift_card_error'));
      return;
    }

    if (
      !hasGiftCard &&
      GIFT_CARD_SKU_LIST.some((item) => item === sku) &&
      basketData?.basketitem_set?.length > 0
    ) {
      setErrorMessage(t('product.add_basket.gift_card_error'));
      return;
    }

    await addProduct({
      product,
      quantity,
      attributes
    })
      .unwrap()
      .then((data) => {
        dispatch(
          basketApi.util.updateQueryData(
            'getBasket',
            undefined,
            (draftBasket) => {
              Object.assign(draftBasket, data.basket);
            }
          )
        );

        data?.basket?.basketitem_set?.map((item) => {
          if (+item?.product.pk === +product) {
            if (!isMobile) {
              toast.open({
                withTitle: true,
                icon: 'bag',
                title: t('product.add_basket.title'),
                message:
                  item?.product?.name.length > 31
                    ? item?.product?.name.substring(0, 31) +
                      '... ' +
                      t('product.add_basket.added')
                    : item?.product?.name,
                linkTitle: t('product.add_basket.link'),
                url: `${ROUTES.BASKET}`,
                className: 'bg-primary',
                titleClassName: 'text-white',
                messageClassName: 'text-white',
                linkClassName: 'text-white ml-2',
                withTitleIconClass: 'pr-1',
                iconSize: 32,
                linkIconSize: 24
              });
            } else {
              setShowBottomSheet(true);
            }
          }
        });
      })
      .then(() => {
        if (shouldOpenMiniBasket) {
          dispatch(setHighlightedItem(product));
          setTimeout(() => {
            dispatch(setHighlightedItem(null));
          }, 3000);
        }
      });
  };

  return [
    addProductToBasket,
    options,
    showBottomSheet,
    setShowBottomSheet,
    errorMessage
  ] as const;
};
