import { api } from '@akinon/next/data/client/api';
import { buildClientRequestUrl } from '@akinon/next/utils';
import { EndPoints } from '..';

export const miscApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createWishlist: builder.mutation<void, any>({
      query: (body) => ({
        url: buildClientRequestUrl(EndPoints.WISHLIST_COLLECTIONS, {
          contentType: 'application/json',
          responseType: 'text'
        }),
        method: 'POST',
        body
      }),
      invalidatesTags: ['Basket']
    }),
    getWishlist: builder.query<any, any>({
      query: () => ({
        url: buildClientRequestUrl(EndPoints.WISHLIST_COLLECTIONS, {
          responseType: 'json'
        })
      }),
      providesTags: ['Basket']
    }),
    deleteWishlist: builder.mutation<void, any>({
      query: (pk) => ({
        url: buildClientRequestUrl(`${EndPoints.WISHLIST_COLLECTIONS}${pk}/`, {
          contentType: 'application/json',
          responseType: 'text'
        }),
        method: 'DELETE'
      })
    }),
    deleteWishlistProduct: builder.mutation<void, any>({
      query: (pk) => ({
        url: buildClientRequestUrl(`${EndPoints.ADDTO_WISHLIST}${pk}/`, {
          contentType: 'application/json',
          responseType: 'text'
        }),
        method: 'DELETE'
      })
    }),
    getWishlistDetails: builder.query<any, void>({
      query: (slug) => ({
        url: buildClientRequestUrl(
          `${EndPoints.WISHLIST_COLLECTIONS}${slug}/`,
          {
            responseType: 'json'
          }
        )
      })
    }),
    addProductToWishlist: builder.mutation<any, any>({
      query: (body) => ({
        url: buildClientRequestUrl(EndPoints.ADDTO_WISHLIST, {
          contentType: 'application/json',
          responseType: 'text'
        }),
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useCreateWishlistMutation,
  useGetWishlistQuery,
  useDeleteWishlistMutation,
  useDeleteWishlistProductMutation,
  useGetWishlistDetailsQuery,
  useAddProductToWishlistMutation
} = miscApi;
