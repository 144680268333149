export const GIFT_CARD_SKU_LIST = [
  '000000090000012008',
  '000000000010009060',
  '000000090000012010',
  '000000090000012003',
  '000000090000012004',
  '000000090000012006',
  '000000090000012002',
  '000000090000012007',
  '000000090000012005',
  '000000090000012009'
];
